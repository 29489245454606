import { useFormContext } from 'FrontRoyalReactHookForm';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Select } from '../Select';

const classes = {
    form: {
        advancedFieldsWrapper: (showingAdvancedFields: boolean) =>
            clsx('flex', 'items-end', 'justify-start', 'flex-wrap', {
                hidden: !showingAdvancedFields,
            }),
        fieldItem: clsx('me-[15px]', 'flex', 'items-center', 'justify-start', 'mb-[10px]'),
        fieldItemLabel: clsx(
            'text-slate-grey',
            'font-semibold',
            'text-[16px]',
            'me-[10px]',
            'mt-2',
            'min-w-[3em]',
            'text-right',
            'inline-block',
        ),
        input: clsx(
            'p-2',
            'border',
            'border-solid',
            'rounded-4',
            'border-slate-grey-mid-light',
            'text-black',
            'bg-white',
        ),
    },
};

export function MessageSearchAdvancedFields({ showingAdvancedFields }: { showingAdvancedFields: boolean }) {
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    const { register } = useFormContext();
    return (
        <div className={classes.form.advancedFieldsWrapper(showingAdvancedFields)}>
            <div className={classes.form.fieldItem}>
                <label className={classes.form.fieldItemLabel} htmlFor="messageTimestampFrom">
                    {t('messaging.from')}
                </label>
                <input {...register('messageTimestampFrom')} type="datetime-local" className={classes.form.input} />
            </div>
            <div className={classes.form.fieldItem}>
                <label className={classes.form.fieldItemLabel} htmlFor="messageTimestampTo">
                    {t('messaging.to')}
                </label>
                <input {...register('messageTimestampTo')} type="datetime-local" className={classes.form.input} />
            </div>
            <div className={classes.form.fieldItem}>
                <label className={classes.form.fieldItemLabel} htmlFor="ordering">
                    {t('messaging.order')}
                </label>
                <Select {...register('ordering')}>
                    <option value="score">{t('messaging.most_relevant')}</option>
                    <option value="time asc">{t('messaging.oldest_to_newest')}</option>
                    <option value="time desc">{t('messaging.newest_to_oldest')}</option>
                </Select>
            </div>
        </div>
    );
}
