import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
    type BaseMessage,
    MessageSearchOrder,
    type MessageSearchQuery,
    type MessageSearchQueryParams,
} from '@sendbird/chat/message';
import { useSendbirdStateContext } from '@sendbird/uikit-react/SendbirdProvider';
import { sendBirdSelectors } from '@sendbird/uikit-react';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { uniqBy } from 'lodash/fp';
import { FormProvider } from 'FrontRoyalReactHookForm';
import { getActiveChannelUrl } from '../../selectors';
import { setShowMessageSearch } from '../../actions';
import { MessageSearchResultsList } from './MessageSearchResultsList';
import { useMessageSearchForm } from './useMessageSearchForm';
import { MessageSearchFormHeader } from './MessageSearchFormHeader';
import { MessageSearchForm } from './MessageSearchForm';

export const MessageSearch = () => {
    const dispatch = useDispatch();
    const [query, setQuery] = useState<MessageSearchQuery | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessages, setResultMessages] = useState<BaseMessage[]>([]);
    const [showingChannelName, setShowingChannelName] = useState(false);
    const activeChannelUrl = useSelector(getActiveChannelUrl);

    const uniqueResultMessages = useMemo(() => uniqBy<BaseMessage>('messageId')(resultMessages), [resultMessages]);

    const sbContext = useSendbirdStateContext();
    const sdk = sendBirdSelectors.getSdk(sbContext);

    const form = useMessageSearchForm();

    const valid = !!form.watch('keyword')?.trim();
    const dirty = form.formState.isDirty;
    const searchChannels = form.watch('searchChannels');

    const submitHandler = useCallback(() => {
        if (!valid) return;

        setQuery(null);
        setResultMessages([]);

        const values = form.getValues();

        const buildQuery = () => {
            const baseParams: MessageSearchQueryParams = {
                // Remove special characters from the keyword; the search API does not support them
                keyword: values.keyword.replace(/[^\s\w]/g, ''),
                messageTimestampFrom: values.messageTimestampFrom ? new Date(values.messageTimestampFrom).getTime() : 0,
                messageTimestampTo: values.messageTimestampTo ? new Date(values.messageTimestampTo).getTime() : 0,
                order: values.ordering === 'score' ? MessageSearchOrder.SCORE : MessageSearchOrder.TIMESTAMP,
                reverse: values.ordering === 'time asc',
            };
            if (searchChannels === 'active') {
                baseParams.channelUrl = activeChannelUrl;
            }
            setQuery(sdk.createMessageSearchQuery(baseParams));
        };
        buildQuery();
        setShowingChannelName(values.searchChannels === 'all');
    }, [activeChannelUrl, form, sdk, searchChannels, valid]);

    const handleCloseClick = useCallback(() => {
        dispatch(setShowMessageSearch(false));
    }, [dispatch]);

    const handleResetClick = useCallback(() => {
        form.reset();
        setQuery(null);
        setResultMessages([]);
    }, [form]);

    const handleLoadMoreClick = useCallback(async () => {
        if (!query) return;

        const queryMessages = (await query?.next()) ?? [];

        setResultMessages(curr => [...curr, ...queryMessages]);
    }, [query]);

    useEffect(() => {
        if (!query) return;
        if (resultMessages.length !== 0) return;

        const fetchMessages = () => {
            if (query && query.hasNext && !query.isLoading) {
                return query.next().then(messages => messages || []);
            }
            return Promise.resolve([]) as Promise<BaseMessage[]>;
        };

        setIsLoading(true);

        fetchMessages().then(messages => {
            setResultMessages(messages);
            setIsLoading(false);
        });
    }, [query, resultMessages.length]);

    const hasNext = query?.hasNext;
    const noResults = !isLoading && !!query && query.totalCount === 0;

    return (
        <FormProvider {...form}>
            <MessageSearchPresentation
                searchChannels={searchChannels}
                handleCloseClick={handleCloseClick}
                dirty={dirty}
                uniqueResultMessages={uniqueResultMessages}
                valid={valid}
                isLoading={isLoading}
                submitHandler={submitHandler}
                handleResetClick={handleResetClick}
                handleLoadMoreClick={handleLoadMoreClick}
                noResults={noResults}
                hasNext={!!hasNext}
                showingChannelName={showingChannelName}
            />
        </FormProvider>
    );
};

function MessageSearchPresentation({
    searchChannels,
    handleCloseClick,
    dirty,
    uniqueResultMessages,
    valid,
    isLoading,
    submitHandler,
    handleResetClick,
    handleLoadMoreClick,
    noResults,
    hasNext,
    showingChannelName,
}: {
    searchChannels: string;
    handleCloseClick: () => void;
    dirty: boolean;
    uniqueResultMessages: BaseMessage[];
    valid: boolean;
    isLoading: boolean;
    submitHandler: () => void;
    handleResetClick: () => void;
    handleLoadMoreClick: () => void;
    noResults: boolean;
    hasNext: boolean;
    showingChannelName: boolean;
}) {
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    return (
        <div className="-ml-[1px] h-full w-full border-s border-solid border-slate-grey-mid-light bg-white">
            <MessageSearchFormHeader searchChannels={searchChannels} handleCloseClick={handleCloseClick} />
            <div className="flex h-[calc(100%-64px)] max-h-full w-full flex-col items-start justify-start overflow-hidden">
                <MessageSearchForm
                    resetDisabled={!dirty && !uniqueResultMessages.length}
                    valid={valid}
                    isLoading={isLoading}
                    submitHandler={submitHandler}
                    handleResetClick={handleResetClick}
                />
                <div className="h-full w-full shrink grow overflow-auto p-5">
                    {!isLoading && noResults && (
                        <p className="text-[16px] font-normal text-slate-grey">{t('messaging.no_results')}</p>
                    )}
                    {!!uniqueResultMessages.length && (
                        <MessageSearchResultsList
                            messages={uniqueResultMessages}
                            showChannelName={showingChannelName}
                        />
                    )}
                    {isLoading && <FrontRoyalSpinner color="force-brand" className="no-delay" />}

                    {hasNext && !isLoading && uniqueResultMessages.length > 0 && (
                        <button
                            type="button"
                            className="flex h-[40px] items-center justify-center rounded-5 bg-slate-grey-extra-light px-[20px] text-[16px] font-semibold text-black hover:bg-slate-grey-mid-light"
                            onClick={handleLoadMoreClick}
                        >
                            {t('messaging.load_more')}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}
