import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import {
    Modal as HerouiModal,
    ModalContent as HerouiModalContent,
    ModalBody as HerouiModalBody,
    ModalHeader as HerouiModalHeader,
    HeroUIProvider,
} from '@heroui/react';
import Button from 'Button';
import { memo, useCallback, useMemo, useState, type FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { type ModalProps } from './Modal.types';

// FIXME: see https://github.com/heroui-inc/heroui/issues/2297
const motionProps = (fadeInDuration = 0.4) => ({
    variants: {
        enter: {
            scale: 1,
            y: 'var(--slide-enter)',
            opacity: 1,
            transition: {
                scale: {
                    duration: 0.4,
                    ease: [0.25, 0.1, 0.25, 1],
                },
                opacity: {
                    duration: fadeInDuration,
                    ease: [0.25, 0.1, 0.25, 1],
                },
                y: {
                    type: 'spring',
                    bounce: 0,
                    duration: 0.6,
                },
            },
        },
        exit: {
            scale: 1, // NextUI default 1.03
            y: 'var(--slide-exit)',
            opacity: 0,
            transition: {
                duration: 0.3,
                ease: [0.25, 0.1, 0.25, 1],
            },
        },
    },
});

const CloseButton: FC<{ onClose?: () => void }> = ({ onClose }) => (
    <Button
        isIconOnly
        onPress={onClose}
        variant="light"
        radius="full"
        className="absolute right-5 top-7 border-none rtl:left-5 rtl:right-auto"
        size="sm"
    >
        <FontAwesomeIcon icon={faXmark} className="text-lg text-black" />
    </Button>
);

const modalBaseSlots = (noSize = false) => ({
    backdrop: ['bg-black bg-opacity-50 px-2 pt-[55px] backdrop-blur-sm sm:pt-[75px] z-[9999]'],
    wrapper: ['z-[10000]'],
    base: ['tailwind-base-container rounded-8 overflow-hidden mx-2.5', noSize ? 'min-w-0 max-w-fit w-unset' : ''],
    header: ['justify-center p-7.5 text-xl font-semibold leading-none text-black'],
    body: ['gap-0 p-0 max-h-[75vh] overflow-y-auto'],
    footer: [''],
    closeButton: [''],
});

export const Modal: FC<ModalProps> = memo(
    ({
        title,
        children,
        onClose,
        size = 'xl',
        classNames,
        dismissOnOverlayClick = true,
        dismissOnEscape = true,
        hideCloseButton = false,
        shadow = 'none',
        duration = 0.4,
    }) => {
        const [isOpen, setIsOpen] = useState(true);

        const handleClose = useCallback(() => {
            setIsOpen(false);
            setTimeout(() => {
                // ensure the animation finishes
                onClose?.();
            }, 300);
        }, [onClose]);

        const slots = useMemo(() => {
            if (!classNames) return modalBaseSlots(size === 'unset');

            return Object.entries(classNames).reduce(
                (prev, [key, value]) => ({
                    ...prev,
                    [key]: twMerge([prev[key as keyof typeof prev], value]),
                }),
                modalBaseSlots(size === 'unset') as typeof classNames,
            );
        }, [classNames, size]);

        return (
            <HeroUIProvider className="hidden">
                <HerouiModal
                    isOpen={isOpen}
                    backdrop="blur"
                    classNames={slots}
                    closeButton={<CloseButton onClose={handleClose} />}
                    size={size === 'unset' ? undefined : size}
                    placement="center"
                    motionProps={motionProps(duration)}
                    onClose={handleClose}
                    isDismissable={dismissOnOverlayClick}
                    isKeyboardDismissDisabled={!dismissOnEscape}
                    hideCloseButton={hideCloseButton}
                    shadow={shadow}
                >
                    <HerouiModalContent>
                        <>
                            {title && <HerouiModalHeader>{title}</HerouiModalHeader>}
                            <HerouiModalBody>
                                {typeof children === 'function' ? children({ onClose: handleClose }) : children}
                            </HerouiModalBody>
                        </>
                    </HerouiModalContent>
                </HerouiModal>
            </HeroUIProvider>
        );
    },
);
