/* eslint-disable max-lines-per-function */
import { type RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { type MessageContentProps } from '@sendbird/uikit-react/ui/MessageContent';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTurnLeft } from '@fortawesome/pro-regular-svg-icons/faArrowTurnLeft';
import { useTargetBrandConfig } from 'FrontRoyalAngular';
import { useSelector, useDispatch } from 'react-redux';
import { type CoreMessageType } from '@sendbird/uikit-react/types/utils';
import { useTranslation } from 'react-i18next';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import { type FileMessage, type MultipleFilesMessage } from '@sendbird/chat/message';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
import { EMOJI_CONTENT_REGEX, useGetChannelMemberNickname } from '../utils';
import { getHighlightedMessageId } from '../selectors';
import { Menus } from './Menus';
import { ReadReceipt } from './ReadReceipt';
import { MessageBody } from './MessageBody';
import { MessageReactions } from './MessageReactions';
import { MemberAvatars } from './MemberAvatars';
import { setShowChannelInfo, setShowProfileUserId } from '../actions';

const classes = {
    container: (isOtherUser: boolean, isHighlighted: boolean) =>
        clsx(
            'flex',
            'p-[2px]',
            'mb-[10px]',
            'transition-all',
            'duration-300',
            'rounded-5',
            'border',
            'border-solid',
            isOtherUser ? 'justify-start' : 'justify-end',
            {
                'border-yellow bg-yellow-50': isHighlighted,
                'border-transparent bg-transparent': !isHighlighted,
            },
        ),
    avatar: clsx('h-[40px]', 'w-[40px]', 'me-2', 'mt-[19px]', 'shrink-0'),
    adminAvatar: clsx('h-[40px]', 'w-[40px]', 'me-2', 'mt-[19px]', 'shrink-0', 'rounded-full'),
    menuAndContentWrapper: (isOtherUser: boolean) =>
        clsx('flex', 'mt-[5px]', 'max-w-[75%]', 'relative', isOtherUser ? 'flex-row' : 'flex-row-reverse'),
    contentContainer: (isOtherUser: boolean) =>
        clsx('flex w-full flex-col', {
            'items-end': !isOtherUser,
        }),
    icons: {
        arrow: clsx('mr-[8px]', 'mt-[-2px]'),
    },
    reactionsContainer: clsx('w-full', 'max-w-full'),
    body: {
        container: (isOtherUser: boolean) =>
            clsx('flex', 'flex-col', 'w-full', isOtherUser ? 'items-start' : 'items-end'),
        replyAndName: (isOtherUser: boolean) =>
            clsx(
                'flex',
                'min-h-[14px]',
                isOtherUser ? 'flex-row' : 'flex-row-reverse me-[15px]',
                'ms-[15px] justify-start',
            ),
        replyIconWrapper: (isOtherUser: boolean) =>
            clsx(
                'text-[14px]',
                'font-semibold',
                'text-slate-grey',
                'flex',
                'items-center',
                isOtherUser ? 'me-[8px]' : 'ms-[8px]',
            ),
        nameAndDate: {
            container: clsx('flex', 'items-center', 'min-h-[14px]'),
            name: clsx(
                'text-[14px]',
                'font-semibold',
                'text-slate-grey',
                'me-2',
                'leading-none',
                'text-start',
                'hover:underline',
            ),
            date: clsx('text-[14px]', 'text-slate-grey', 'leading-none'),
        },
        failedMessage: {
            container: clsx('flex', 'items-center', 'justify-end'),
            icon: clsx('text-coral', 'text-[14px]', 'me-[5px]', '-mt-[2px]'),
            text: clsx('text-[14px]', 'text-coral', 'leading-none'),
        },
        parentReply: clsx(
            'flex',
            'w-fit',
            'min-h-[42px]',
            'max-w-[100%]',
            'z-[1]',
            'text-[14px]',
            'py-[10px]',
            'px-[15px]',
            'rounded-10',
            'text-[16px]',
            'text-black',
            'text-opacity-75',
            'bg-slate-grey-mid',
            'mb-[-5px]',
        ),
        content: (isOtherUser: boolean, showEmojis: boolean, isFailedUserMessage: boolean, isEmojiContent: boolean) =>
            clsx(
                'w-fit',
                'z-[5]',
                'min-h-[40px]',
                'min-w-[50px]',
                'max-w-full',
                'first:*:mx-[15px]',
                { 'first:*:mb-[5px]': showEmojis },
                'rounded-10',
                { 'text-[16px] first:*:my-[8px]': !isEmojiContent },
                { 'text-[40px] first:*:-mb-[12px] tracking-wider': isEmojiContent },
                'flex',
                'flex-col',
                isEmojiContent
                    ? 'bg-transparent'
                    : isOtherUser
                    ? clsx('bg-slate-grey-lightest', 'text-black')
                    : clsx(
                          'text-white',
                          'font-medium',
                          'bg-gradient-to-r',
                          'quantic:from-[#FF4D63]',
                          'quantic:to-[#FE5F5F]',
                          'valar:from-blue',
                          'valar:to-blue',
                      ),
                { 'opacity-50': isFailedUserMessage && !isOtherUser },
            ),
    },
};

type Props = MessageContentProps & {
    onRefSet: (messageId: number, ref: RefObject<HTMLDivElement | null> | null) => void;
};

export const MessageContent = ({ message, userId, onRefSet, ...rest }: Props) => {
    const dispatch = useDispatch();
    const BrandConfig = useTargetBrandConfig();
    const { currentChannel } = useGroupChannelContext();
    const getChannelMemberNickname = useGetChannelMemberNickname();
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    const isFromOtherUser = useMemo(
        () => message.isAdminMessage() || message.sender.userId !== userId,
        [message, userId],
    );
    const highlightedMessageId = useSelector(getHighlightedMessageId);
    const isHighlighted = highlightedMessageId === message.messageId;
    const isEmojiContent = EMOJI_CONTENT_REGEX.test(message.message);

    const showEmojisInMessage = useMemo(() => !!message.reactions.length, [message.reactions.length]);
    const hasParentMessage = useMemo(() => !!message.parentMessage, [message.parentMessage]);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const isFailedMessage = useMemo(
        () =>
            (message.isUserMessage() || message.isFileMessage()) &&
            ['failed', 'canceled'].includes(message.sendingStatus),
        [message],
    );

    const getMessageMember = useCallback(
        (msg: FileMessage | MultipleFilesMessage) => currentChannel?.members.find(m => m.userId === msg.sender.userId),
        [currentChannel],
    );

    const [parentMessageName, currentMessageName] = useMemo(() => {
        if (!message.parentMessage || !currentChannel) return ['', ''];

        const parentMessage = message.parentMessage as CoreMessageType;

        const parentMessageSender = parentMessage.isAdminMessage()
            ? t('messaging.admin')
            : parentMessage.sender.userId === userId
            ? t('messaging.you')
            : getMessageMember(parentMessage)
            ? getChannelMemberNickname(getMessageMember(parentMessage)!)
            : parentMessage.sender.nickname;

        const messageSender = message.isAdminMessage()
            ? t('messaging.admin')
            : message.sender.userId === userId
            ? t('messaging.you')
            : getMessageMember(message)
            ? getChannelMemberNickname(getMessageMember(message)!)
            : message.sender.nickname;

        return [parentMessageSender, messageSender];
    }, [message, t, userId, currentChannel, getMessageMember, getChannelMemberNickname]);

    const [hovered, setHovered] = useState(false);

    useEffect(() => {
        onRefSet(message.messageId, wrapperRef);

        return () => {
            onRefSet(message.messageId, null);
        };
    }, [onRefSet, wrapperRef, message]);

    return (
        <div
            ref={wrapperRef}
            className={classes.container(isFromOtherUser, isHighlighted)}
            onMouseOver={() => setHovered(true)}
            onFocus={() => undefined}
            onMouseLeave={() => {
                setHovered(false);
            }}
        >
            {isFromOtherUser &&
                (message.isAdminMessage() ? (
                    <img
                        className={classes.adminAvatar}
                        src={BrandConfig.avatarDefault || BrandConfig.avatarAlt!}
                        alt="admin"
                    />
                ) : (
                    <button
                        type="button"
                        className="h-full self-end"
                        onClick={() => {
                            dispatch(setShowChannelInfo(false));
                            dispatch(setShowProfileUserId(message.sender.userId));
                        }}
                    >
                        <MemberAvatars members={[message.sender]} className={classes.avatar} showOnlineStatus={false} />
                    </button>
                ))}

            <div className={classes.body.container(isFromOtherUser)}>
                <div className={classes.body.replyAndName(isFromOtherUser)}>
                    {message.parentMessage && (
                        <div className={classes.body.replyIconWrapper(isFromOtherUser)}>
                            <FontAwesomeIcon icon={faArrowTurnLeft} className={classes.icons.arrow} />
                            {t('messaging.repliedTo', {
                                parent: parentMessageName,
                                current: currentMessageName,
                            })}
                        </div>
                    )}
                    <div className={classes.body.nameAndDate.container}>
                        {isFromOtherUser && !hasParentMessage && !message.isAdminMessage() && (
                            <button
                                type="button"
                                className={classes.body.nameAndDate.name}
                                onClick={() => {
                                    dispatch(setShowChannelInfo(false));
                                    dispatch(setShowProfileUserId(message.sender.userId));
                                }}
                            >
                                {getMessageMember(message)
                                    ? getChannelMemberNickname(getMessageMember(message)!)
                                    : message.sender.nickname}
                            </button>
                        )}
                        {!isFromOtherUser && !message.isAdminMessage() && <ReadReceipt message={message} />}
                        {!isFromOtherUser && isFailedMessage ? (
                            <div className={classes.body.failedMessage.container}>
                                <FontAwesomeIcon
                                    icon={faExclamationCircle}
                                    className={classes.body.failedMessage.icon}
                                />
                                <span className={classes.body.failedMessage.text}>{t('messaging.failedMessage')}</span>
                            </div>
                        ) : (
                            <div className={classes.body.nameAndDate.date}>
                                {moment(message.createdAt).format('hh:mm A')}
                            </div>
                        )}
                    </div>
                </div>
                <div className={classes.menuAndContentWrapper(isFromOtherUser)}>
                    <div className={classes.contentContainer(isFromOtherUser)}>
                        {message.parentMessage && (
                            <div className={classes.body.parentReply}>
                                <MessageBody message={message.parentMessage} parentMessage />
                            </div>
                        )}

                        <div
                            className={classes.body.content(
                                isFromOtherUser,
                                showEmojisInMessage,
                                isFailedMessage,
                                isEmojiContent,
                            )}
                        >
                            <MessageBody message={message} parentMessage={false} />
                        </div>
                        {showEmojisInMessage && (
                            <div className={classes.reactionsContainer}>
                                <MessageReactions
                                    message={message}
                                    nicknamesMap={rest.nicknamesMap!}
                                    userId={userId}
                                    isFromOtherUser={isFromOtherUser}
                                />
                            </div>
                        )}
                    </div>
                    {hovered && <Menus message={message} isFromOtherUser={isFromOtherUser} userId={userId} {...rest} />}
                </div>
            </div>
        </div>
    );
};
