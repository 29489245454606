import { type CoreMessageType } from '@sendbird/uikit-react/types/utils';
import clsx from 'clsx';
import { Emoji } from 'emoji-picker-react';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import { twMerge } from 'tailwind-merge';
import { Tooltip } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveChannelUrl } from '../selectors';
import { setActiveChannelUrl } from '../actions';
import { useEmojiPicker } from '../utils/useEmojiPicker';
import { ChannelWrapperContext } from '../ChannelWrapperContext';
import { AddEmojiIcon } from './AddEmojiIcon';

type Props = {
    message: CoreMessageType;
    nicknamesMap: Map<string, string>;
    userId: string;
    isFromOtherUser: boolean;
};

const classes = {
    container: (isFromOtherUser: boolean) =>
        clsx('w-full', 'min-h-[24px]', 'pt-[7px]', 'pb-[3px]', 'bg-transparent', 'text-gray-700', {
            'text-start ps-[2px]': isFromOtherUser,
            'text-end pe-[0px]': !isFromOtherUser,
        }),
    emojiList: {
        item: (currentUserReacted: boolean) =>
            clsx(
                'inline-flex',
                'min-w-[50px]',
                'items-center',
                'justify-center',
                'me-[5px]',
                'border',
                'border-solid',
                { 'border-[#F0F0F0]': !currentUserReacted, 'border-blue-300': currentUserReacted },
                'rounded-4',
                'px-[2px]',
                'py-[2px]',
                { 'bg-white': !currentUserReacted, 'bg-blue-100': currentUserReacted },
            ),
        emoji: clsx('inline-block', 'me-[3px]', 'pointer-events-none'),
        count: clsx('text-[12px]', 'text-gray-700'),
        addEmojiButton: clsx('hover:bg-beige-dark', 'bg-beige'),
        addEmojiIcon: clsx('relative', '-top-[1px]'),
    },
};

// eslint-disable-next-line max-lines-per-function
export const MessageReactions = ({ message, nicknamesMap, userId, isFromOtherUser }: Props) => {
    const { reactions = [] } = message;
    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
    const { currentChannel } = useGroupChannelContext();
    const activeChannelUrl = useSelector(getActiveChannelUrl);
    const dispatch = useDispatch();

    const addEmojiRef = useRef<HTMLButtonElement>(null);
    const channelWrapper = useContext(ChannelWrapperContext);

    const updateChannelIfNeeded = useCallback(() => {
        if (currentChannel && currentChannel?.url !== activeChannelUrl) {
            dispatch(setActiveChannelUrl(currentChannel!.url));
        }
    }, [activeChannelUrl, currentChannel, dispatch]);

    const handleEmojiSelection = useCallback(
        async (emojiKey: string) => {
            const reactionEvent = await currentChannel!.addReaction(message, emojiKey);
            message.applyReactionEvent(reactionEvent);
            setEmojiPickerOpen(false);
            updateChannelIfNeeded();
        },
        [currentChannel, message, updateChannelIfNeeded],
    );

    const handleClickOutsideEmojiPicker = useCallback(
        (e: Event) => {
            // Don't consider clicking the add emoji button as an "outside click"
            if (e.target !== addEmojiRef.current) {
                setEmojiPickerOpen(false);
            }
        },
        [setEmojiPickerOpen],
    );

    const { EmojiPicker, setPositionFromElement } = useEmojiPicker({
        open: emojiPickerOpen,
        onEmojiSelection: handleEmojiSelection,
        showReactions: true, // show the reactions picker first instead of the full emoji menu
        onClickOutside: handleClickOutsideEmojiPicker,
    });

    const handleCurrentReactionClick = useCallback(
        async (emojiKey: string) => {
            const hasOwnReaction = reactions.some(r => r.key === emojiKey && r.userIds.includes(userId));
            let reactionEvent;
            if (hasOwnReaction) {
                reactionEvent = await currentChannel!.deleteReaction(message, emojiKey);
            } else {
                reactionEvent = await currentChannel!.addReaction(message, emojiKey);
            }
            message.applyReactionEvent(reactionEvent);
            updateChannelIfNeeded();
        },
        [reactions, message, updateChannelIfNeeded, userId, currentChannel],
    );

    const handleAddReactionClick = useCallback(
        (e: React.MouseEvent) => {
            setPositionFromElement(e.currentTarget, channelWrapper.current ?? undefined);
            setEmojiPickerOpen(v => !v);
        },
        [channelWrapper, setPositionFromElement],
    );

    return (
        <div className={classes.container(isFromOtherUser)}>
            {reactions.map(r => {
                const numReactions = r.userIds.length;
                const currentUserReacted = r.userIds.includes(userId);
                return (
                    <Tooltip
                        key={r.key}
                        title={r.userIds
                            .map(id => nicknamesMap.get(id))
                            .filter(Boolean)
                            .join(', ')}
                    >
                        <button
                            type="button"
                            onClick={() => handleCurrentReactionClick(r.key)}
                            className={classes.emojiList.item(currentUserReacted)}
                        >
                            <span className={classes.emojiList.emoji}>
                                <Emoji unified={r.key} key={r.key} size={18} />
                            </span>
                            <span className={classes.emojiList.count}>{numReactions}</span>
                        </button>
                    </Tooltip>
                );
            })}
            {message.reactions.length > 0 && (
                <button
                    type="button"
                    ref={addEmojiRef}
                    onClick={handleAddReactionClick}
                    className={twMerge(classes.emojiList.item(false), classes.emojiList.addEmojiButton)}
                >
                    <span className={classes.emojiList.emoji}>
                        <span className={classes.emojiList.addEmojiIcon}>
                            <AddEmojiIcon width="18px" height="18px" />
                        </span>
                    </span>
                </button>
            )}
            {EmojiPicker}
        </div>
    );
};
