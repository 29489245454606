import { type CoreMessageType } from '@sendbird/uikit-react/types/utils';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons';
import { type MouseEvent, useCallback, useContext, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { type ContextItemPosition } from '../../messaging.types';
import { calculateContextItemPosition } from '../../utils/calculateContextItemPosition';
import { ChannelWrapperContext } from '../../ChannelWrapperContext';
import { setActiveChannelUrl } from '../../actions';
import { getActiveChannelUrl } from '../../selectors';
import { actions } from '../../messagingSlice';
import { ConfirmDeleteModal } from './ConfirmDeleteModal';
import { ContextMenu } from './ContextMenu';

const { setReplyMessageId } = actions;

type Props = {
    open: boolean;
    message: CoreMessageType;
    messageIsFromOtherUser: boolean;
    onClickButton: () => void;
    onClickMenuItem?: () => void;
};

const classes = {
    trigger: {
        wrapper: (open: boolean) =>
            clsx(
                'flex',
                'h-[40px]',
                'w-[40px]',
                'items-center',
                'justify-center',
                'rounded-5',
                { 'hover:bg-slate-grey-lightest': !open },
                { 'bg-slate-grey-lightest': open },
            ),
        icon: clsx('text-[28px]', 'text-slate-grey'),
    },
};

export const MessageContextMenu = ({
    open,
    message,
    messageIsFromOtherUser,
    onClickButton,
    onClickMenuItem,
}: Props) => {
    const dispatch = useDispatch();
    const triggerRef = useRef<HTMLButtonElement>(null);
    const deleteModalRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const { currentChannel } = useGroupChannelContext();
    const activeChannelUrl = useSelector(getActiveChannelUrl);
    const channelWrapper = useContext(ChannelWrapperContext);
    const [position, setPosition] = useState<ContextItemPosition>({ height: 0, width: 0, top: 0, left: 0 });
    const [confirmingDelete, setConfirmingDelete] = useState(false);
    const hasReply = !!message.threadInfo?.replyCount;
    const hasParent = !!message.parentMessage;

    useClickAway(deleteModalRef, () => setConfirmingDelete(false));
    useClickAway(menuRef, () => {
        if (open) onClickButton();
    });

    const handleTriggerClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            const menuPosition = calculateContextItemPosition(e.currentTarget, channelWrapper?.current ?? undefined, {
                buffer: 10,
                maxWidth: 200,
            });
            setPosition(menuPosition);
            onClickButton();
        },
        [onClickButton, channelWrapper],
    );

    const handleReplyClick = useCallback(() => {
        dispatch(setReplyMessageId(message.messageId));
        dispatch(setActiveChannelUrl(currentChannel!.url));
        onClickMenuItem?.();
    }, [dispatch, message, currentChannel, onClickMenuItem]);

    const handleCopyClick = useCallback(() => {
        navigator.clipboard.writeText(message.message);
        onClickMenuItem?.();
    }, [message, onClickMenuItem]);

    const handleDeleteClick = useCallback(() => {
        setConfirmingDelete(true);
        onClickMenuItem?.();
    }, [onClickMenuItem]);

    const handleConfirmDelete = useCallback(async () => {
        if (!currentChannel) return;
        await currentChannel.deleteMessage(message);
        setConfirmingDelete(false);
    }, [currentChannel, message]);

    if (message.isAdminMessage()) return null;

    return (
        <>
            {/* Trigger Button */}
            <button
                type="button"
                ref={triggerRef}
                className={classes.trigger.wrapper(open)}
                onKeyDown={() => undefined}
                tabIndex={-1}
                onClick={handleTriggerClick}
            >
                <FontAwesomeIcon className={classes.trigger.icon} icon={faEllipsisV} />
            </button>

            {/* Context Menu */}
            {ReactDOM.createPortal(
                <ContextMenu
                    open={open}
                    menuRef={menuRef}
                    onReplyClick={handleReplyClick}
                    onCopyClick={handleCopyClick}
                    onDeleteClick={handleDeleteClick}
                    hasReply={hasReply}
                    hasParent={hasParent}
                    messageIsFromOtherUser={messageIsFromOtherUser}
                    activeChannelUrl={activeChannelUrl}
                    position={position}
                />,
                document.getElementsByTagName('body')[0],
            )}

            <ConfirmDeleteModal
                open={confirmingDelete}
                deleteModalRef={deleteModalRef}
                onConfirmDelete={handleConfirmDelete}
                onCancel={() => setConfirmingDelete(false)}
                onClose={() => setConfirmingDelete(false)}
            />
        </>
    );
};
