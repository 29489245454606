import { createAsyncThunk } from '@reduxjs/toolkit';
import { SendbirdSdkProvider } from 'SendbirdSdkProvider';
import { careerProfilesApi } from 'CareerProfiles';
import { type AnyObject } from '@Types';
import { actions } from './messagingSlice';

export const {
    setActiveChannelUrl,
    setOpenChat,
    setReplyMessageId,
    setLoading,
    setShowNewMessage,
    setPresetNewMessageRecipientId,
    setShowChannelInfo,
    setShowProfileUserId,
    setUserChannelMap,
    setShowMessageSearch,
    setTargetSearchMessage,
    setHighlightedMessageId,
    setNetworkContactUserId,
    registerChannel,
    setUsersToMessage,
    reset,
    addAvailableUser,
    addSuggestedUser,
    setAvailableUsers,
    addAvailableUsers,
} = actions;

export const openChatAndSelectUser = createAsyncThunk(
    'messaging/openChatAndCreateDraftChannelIfNeeded',
    async (
        { currentUserId, token, userToMessageId }: { currentUserId: string; userToMessageId: string; token: string },
        { dispatch },
    ) => {
        dispatch(setNetworkContactUserId(userToMessageId));
        dispatch(setLoading(true));
        dispatch(setOpenChat(true));
        dispatch(setActiveChannelUrl(''));

        // If we have already loaded Messages and its usersToMessage data, this will add the user to the store.
        // Otherwise, if we haven't already loaded Messages, it will fetch the user but not add it to the store,
        // but the networkContactUserId will be included in the data that we fetch in `useGetStudentsToMessage`.
        const sdk = SendbirdSdkProvider.sdk!;
        await sdk.connect(currentUserId, token);

        const userToMessageProfile = await careerProfilesApi.makeRequest('getProfileForSendbirdById', userToMessageId);

        const userQuery = sdk.createApplicationUserListQuery({ userIdsFilter: [userToMessageId] });

        const [user] = await userQuery.next();

        const newAvailableUser = {
            id: userToMessageId,
            profile: userToMessageProfile,
            serializedUser: user.serialize() as AnyObject,
        };

        dispatch(addAvailableUser({ user: newAvailableUser.serializedUser, profile: newAvailableUser.profile }));
        dispatch(setPresetNewMessageRecipientId(newAvailableUser.id));
        dispatch(setShowNewMessage(true));
        dispatch(setLoading(false));
    },
);
