import { faVolumeHigh } from '@fortawesome/pro-regular-svg-icons/faVolumeHigh';
import { faFileDownload } from '@fortawesome/pro-regular-svg-icons/faFileDownload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type PropsWithoutRef, useState, type FC, useCallback } from 'react';
import clsx from 'clsx';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import Linkify from 'linkify-react';
import { type BaseMessage, type FileMessage } from '@sendbird/chat/message';
import { faCirclePlay } from '@fortawesome/pro-solid-svg-icons';
import { isImageMessage, isImageOrVideo, isVideoMessage } from '../utils';
import { FileViewer } from './FileViewer';

const classes = {
    textBody: clsx('break-anywhere', 'select-text'),
    link: clsx('hover:underline', 'font-semibold'),
    fileLink: {
        container: clsx('flex', 'items-center'),
        icon: clsx('me-[5px]'),
        text: clsx('break-anywhere'),
    },
    imageAndVideo: {
        buttonWrapper: (loaded: boolean) =>
            clsx({
                'relative m-0 flex h-[140px] w-[140px] items-center justify-center p-0': !loaded,
            }),
        content: (loaded: boolean) =>
            clsx(
                'animate-fade-in',
                'rounded-3',
                {
                    hidden: !loaded,
                    'border border-solid border-transparent hover:border-white': loaded,
                },
                'max-h-[140px]',
                'max-w-[250px]',
            ),
        videoWrapper: clsx('relative', 'w-full', 'h-full'),
        playButton: clsx(
            'text-white',
            'text-[48px]',
            'absolute',
            'top-[calc(50%-24px)]',
            'left-[calc(50%-24px)]',
            'z-[1]',
            'opacity-50',
        ),
        spinner: (loaded: boolean) =>
            clsx(
                'w-[140px]',
                'h-[140px]',
                'no-delay',
                'centered',
                'm-0',
                '-mt-1',
                'flex',
                'justify-center',
                'items-center',
                {
                    hidden: loaded,
                },
            ),
    },
};

const TextMessageBody = ({ message, className }: { message: string; className: string }) => {
    const Link = useCallback(
        ({ attributes, content }: { attributes: PropsWithoutRef<JSX.IntrinsicElements['a']>; content: string }) => (
            <a {...attributes} target="_blank" rel="noreferrer" className={classes.link}>
                {content}
            </a>
        ),
        [],
    );

    return (
        <Linkify as="div" className={className} options={{ render: Link }}>
            {message}
        </Linkify>
    );
};

const FileMessageBody = ({ message, parentMessage }: { message: FileMessage; parentMessage: boolean }) => {
    const [showViewer, setShowViewer] = useState(false);
    const [loaded, setLoaded] = useState(false);

    if (message.sendingStatus === 'failed') {
        setTimeout(() => {
            // If we get here and for some reason the message failed to ever send
            // at least stop the spinner from spinning indefinitely
            // FIXME: https://trello.com/c/WuJU5iJj/89-feat-handle-messages-that-will-never-send-1
            setLoaded(v => v || true);
        }, 3500);
    }

    if (isImageOrVideo(message)) {
        return (
            <>
                <button
                    onClick={() => setShowViewer(true)}
                    type="button"
                    className={classes.imageAndVideo.buttonWrapper(loaded)}
                >
                    {isImageMessage(message) && (
                        <img
                            src={message.url}
                            alt={message.name || 'image'}
                            className={classes.imageAndVideo.content(loaded)}
                            onLoad={() => setLoaded(true)}
                        />
                    )}
                    {isVideoMessage(message) && (
                        <div className={classes.imageAndVideo.videoWrapper}>
                            <FontAwesomeIcon icon={faCirclePlay} className={classes.imageAndVideo.playButton} />
                            <video
                                src={message.url}
                                className={classes.imageAndVideo.content(true)}
                                onCanPlay={() => setLoaded(true)}
                                // FIXME: need to check try and resend, but this stops the infinite loader
                                onError={() => setLoaded(true)}
                            >
                                <track kind="captions" />
                            </video>
                        </div>
                    )}
                    <FrontRoyalSpinner
                        className={classes.imageAndVideo.spinner(loaded)}
                        color={parentMessage ? 'force-brand' : 'force-white'}
                    />
                </button>
                <FileViewer open={showViewer} message={message} onCancel={() => setShowViewer(false)} />
            </>
        );
    }

    return (
        <div>
            <a
                href={message.url}
                target="_blank"
                rel="noopener noreferrer"
                download
                className={classes.fileLink.container}
            >
                <FontAwesomeIcon
                    icon={message.type.match('mp3') ? faVolumeHigh : faFileDownload}
                    className={classes.fileLink.icon}
                />
                <p className={classes.fileLink.text}>{message.name}</p>
            </a>
        </div>
    );
};

export const MessageBody: FC<{ message: BaseMessage; parentMessage: boolean }> = ({ message, parentMessage }) => {
    if (message.isAdminMessage()) return <TextMessageBody className={classes.textBody} message={message.message} />;

    if (message.isUserMessage()) return <TextMessageBody className={classes.textBody} message={message.message} />;

    if (message.isFileMessage()) return <FileMessageBody message={message} parentMessage={parentMessage} />;

    if (message.isMultipleFilesMessage()) {
        // TBD
        return <div />;
    }

    return <div />;
};
