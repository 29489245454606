import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import clsx from 'clsx';

const classes = {
    header: {
        container: clsx(
            'w-full',
            'h-[64px]',
            'ps-5',
            'pe-3',
            'flex',
            'items-center',
            'justify-between',
            'border-b',
            'border-solid',
            'border-slate-grey-mid-light',
        ),
        title: clsx('text-lg', 'font-semibold', 'text-black'),
        closeButton: clsx(
            'h-[40px]',
            'w-[40px]',
            'flex',
            'rounded-5',
            'items-center',
            'justify-center',
            'text-black',
            'text-lg',
            'hover:bg-slate-grey-lightest',
        ),
    },
};

export function MessageSearchFormHeader({
    searchChannels,
    handleCloseClick,
}: {
    searchChannels: string;
    handleCloseClick: () => void;
}) {
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    return (
        <div className={classes.header.container}>
            <h3 className={classes.header.title}>
                {t(`messaging.${searchChannels === 'all' ? 'search_all_chats' : 'search_this_chat'}`)}
            </h3>
            <button type="button" className={classes.header.closeButton} onClick={handleCloseClick}>
                <FontAwesomeIcon icon={faTimes} />
            </button>
        </div>
    );
}
