import clsx from 'clsx';
import { type ButtonHTMLAttributes, type ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

const classes = {
    button: (variant: 'danger' | 'standard') =>
        clsx(
            'border',
            'border-solid',

            'rounded-2',
            'w-full',
            'h-[40px]',
            'flex',
            'items-center',
            'justify-center',
            'uppercase',
            'hover:bg-slate-grey-lightest',
            'cursor-pointer',
            'disabled:cursor-not-allowed',
            {
                [clsx('text-slate-grey', 'border-slate-grey-mid-light')]: variant === 'standard',
                [clsx('text-coral', 'border-coral', 'border-opacity-25', 'hover:bg-coral', 'hover:bg-opacity-15')]:
                    variant === 'danger',
            },
            'font-semibold',
            'mb-[10px]',
            'transition-all',
        ),
};

export const DetailsPanelActionButton = ({
    children,
    className,
    variant = 'standard',
    ...rest
}: ButtonHTMLAttributes<HTMLButtonElement> & {
    children: ReactNode;
    className?: string;
    variant?: 'danger' | 'standard';
}) => (
    <button type="button" {...rest} className={twMerge(classes.button(variant), className)}>
        {children}
    </button>
);
