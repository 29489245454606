import { careerProfilesApi } from 'CareerProfiles';
import { useSuspenseQuery } from 'ReduxHelpers';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useGetUserChannelMap } from './useGetUserChannelMap';
import { useGetUsersAndCareerProfiles } from './useGetUsersAndCareerProfiles';
import { getNetworkContactUserId } from '../selectors';

export const useGetStudentsToMessage = () => {
    const [usersWithExistingMessages, userChannelMap] = useGetUserChannelMap();
    const networkContactUserId = useSelector(getNetworkContactUserId);

    const relevantUserIds = useMemo(
        () => [...usersWithExistingMessages, networkContactUserId].filter(v => !!v) as string[],
        [networkContactUserId, usersWithExistingMessages],
    );

    const { data: recommendedProfiles = [] } = useSuspenseQuery(careerProfilesApi, 'getRecommendedClassmates');
    const { data: relevantUserProfiles = [] } = useSuspenseQuery(
        careerProfilesApi,
        'getAllRequiredProfilesForSendbirdInBatches',
        relevantUserIds,
    );
    const { availableUsers, suggestedUsers, profilesById } = useGetUsersAndCareerProfiles({
        recommendedProfiles,
        relevantUserProfiles,
        usersWithExistingMessages,
    });

    return { availableUsers, suggestedUsers, profilesById, userChannelMap };
};
