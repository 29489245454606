import { useForm, yupResolver } from 'FrontRoyalReactHookForm';
import * as Yup from 'yup';

const formSchema = Yup.object().shape({
    keyword: Yup.string().required(),
    messageTimestampFrom: Yup.date().nullable(),
    messageTimestampTo: Yup.date().nullable(),
    ordering: Yup.string().oneOf(['score', 'time asc', 'time desc']).required(),
    searchChannels: Yup.string().oneOf(['all', 'active']).required(),
});

export function useMessageSearchForm() {
    const formExports = useForm({
        defaultValues: {
            keyword: '',
            messageTimestampFrom: null,
            messageTimestampTo: null,
            ordering: 'score',
            searchChannels: 'active',
        },
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(formSchema),
    });

    return formExports;
}
