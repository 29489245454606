import clsx from 'clsx';
import { useCallback, useContext } from 'react';
import { type CoreMessageType } from '@sendbird/uikit-react/types/utils';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveChannelUrl } from '../selectors';
import { setActiveChannelUrl } from '../actions';
import { useEmojiPicker } from '../utils/useEmojiPicker';
import { ChannelWrapperContext } from '../ChannelWrapperContext';
import { AddEmojiIcon } from './AddEmojiIcon';

type Props = {
    open: boolean;
    onClickButton: () => void;
    message: CoreMessageType;
};

const classes = {
    button: (open: boolean) =>
        clsx('h-[40px]', 'w-[40px]', 'flex', 'items-center', 'justify-center', 'rounded-5', {
            'bg-slate-grey-lightest': open,
            'hover:bg-slate-grey-lightest': !open,
        }),
    icon: clsx('text-[24px]', 'flex', 'justify-center', 'items-center', 'text-slate-grey'),
};

export const MessageReactionsMenu = ({ open, onClickButton, message }: Props) => {
    const { currentChannel } = useGroupChannelContext();
    const channelWrapper = useContext(ChannelWrapperContext);
    const activeChannelUrl = useSelector(getActiveChannelUrl);
    const dispatch = useDispatch();

    const updateChannelIfNeeded = useCallback(() => {
        if (currentChannel && currentChannel?.url !== activeChannelUrl) {
            dispatch(setActiveChannelUrl(currentChannel!.url));
        }
    }, [activeChannelUrl, currentChannel, dispatch]);

    const handleEmojiSelection = useCallback(
        async (emojiKey: string) => {
            const reactionEvent = await currentChannel!.addReaction(message, emojiKey);
            message.applyReactionEvent(reactionEvent);
            onClickButton();
            updateChannelIfNeeded();
        },
        [currentChannel, message, onClickButton, updateChannelIfNeeded],
    );

    const { EmojiPicker, setPositionFromElement } = useEmojiPicker({
        open,
        onEmojiSelection: handleEmojiSelection,
        showReactions: true,
    });

    return (
        <>
            <div
                role="button"
                className={classes.button(open)}
                onKeyDown={() => undefined}
                tabIndex={-1}
                onClick={e => {
                    setPositionFromElement(e.currentTarget, channelWrapper?.current ?? undefined);
                    onClickButton(); // opens and closes the emoji picker
                }}
            >
                <AddEmojiIcon />
            </div>
            {EmojiPicker}
        </>
    );
};
