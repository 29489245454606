/* eslint-disable max-lines-per-function */
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getMostRecentPositionText, getPrograms } from 'CareerProfiles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { useSendbirdStateContext } from '@sendbird/uikit-react/SendbirdProvider';
import sendbirdSelectors from '@sendbird/uikit-react/sendbirdSelectors';
import { faClock, faLocationDot } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';
import { useCurrentUserIguanaObject, useNavigationHelper } from 'FrontRoyalAngular';
import { type GroupChannel as GroupChannelType } from '@sendbird/chat/groupChannel';
import { messagingLocation, useGetConnectionStatus, useGetStudentsToMessage } from '../utils';
import { useChannelMetaData } from '../utils/useChannelMetaData';
import { MemberAvatars } from './MemberAvatars';
import {
    setActiveChannelUrl,
    setOpenChat,
    setPresetNewMessageRecipientId,
    setShowChannelInfo,
    setShowNewMessage,
    setShowProfileUserId,
} from '../actions';
import { DetailsPanelActionButton } from './DetailsPanelActionButton';

type Props = {
    profileUserId: string;
    channel?: GroupChannelType;
    onSendMessageClick?: () => void;
};

const classes = {
    container: clsx('h-full', 'flex-col', 'flex'),
    header: {
        container: clsx(
            'h-[64px]',
            'grow-0',
            'shrink-0',
            'basis-[64px]',
            'border-b',
            'border-solid',
            'border-slate-grey-mid-light',
            'flex',
            'items-center',
            'justify-between',
            'ps-5',
            'pe-3',
        ),
        text: clsx('font-semibold', 'text-lg', 'text-black'),
        closeButton: clsx(
            'hover:bg-slate-grey-lightest',
            'flex',
            'justify-center',
            'items-center',
            'rounded-5',
            'p-2',
            'cursor-pointer',
        ),
        closeIcon: clsx('text-black', 'text-lg', 'overflow-hidden'),
    },
    body: {
        container: clsx('h-[calc(100%-64px)]', 'overflow-y-auto'),
    },
    profile: {
        container: clsx('p-5', 'border-b', 'border-solid', 'border-slate-grey-mid-light'),
        avatar: {
            container: clsx('mb-5', 'flex', 'justify-center', 'items-center'),
            avatarImage: clsx('h-[100px]', 'w-[100px]'),
        },
        nameAndPosition: {
            container: clsx('mb-5'),
            userName: clsx('text-xl', 'font-semibold', 'text-black'),
            mostRecentPosition: clsx('text-[16px]', 'leading-[22px]', 'text-black'),
        },
        currentStateData: {
            container: clsx('mb-[30px]'),
            item: clsx('flex', 'items-center', 'justify-start', 'mb-2'),
            itemIcon: clsx('text-[16px]', 'me-3', 'text-slate-grey', 'leading-[22px]', 'w-[16px]', 'inline-block'),
            itemOnlineStatus: (isOnline: boolean) =>
                clsx('h-[10px]', 'w-[10px]', 'rounded-full', 'me-[14px]', 'ms-[3px]', {
                    'bg-green': isOnline,
                    'bg-transparent border border-solid border-slate-grey': !isOnline,
                }),
            itemText: clsx('text-[16px]', 'text-black', 'leading-[22px]'),
        },
        actions: {
            container: clsx(),
        },
    },
    programs: {
        container: clsx('px-5', 'py-[30px]', 'border-b', 'border-solid', 'border-slate-grey-mid-light'),
        heading: clsx('font-semibold', 'text-[16px]', 'mb-5', 'text-black'),
        list: clsx('list-none', 'p-0'),
        listItem: clsx('mb-[10px]', 'flex', 'items-start', 'justify-start', 'text-black'),
        logo: clsx('w-[20px]', 'h-[20px]', 'me-2', 'mt-[2px]'),
    },
    additionalActions: {
        container: clsx('px-5', 'py-[30px]'),
        heading: clsx('font-semibold', 'text-[16px]', 'mb-5', 'text-black'),
    },
    noData: {
        container: clsx('p-5', 'flex', 'justify-center', 'items-center'),
        message: clsx('text-slate-grey', 'italic', 'text-[16px]', 'animate-fade-in'),
    },
};

export const UserProfile = ({ profileUserId, channel, onSendMessageClick }: Props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('back_royal');
    const currentUser = useCurrentUserIguanaObject();
    const { loadRoute } = useNavigationHelper();
    const { profilesById, availableUsers } = useGetStudentsToMessage();
    const metaData = useChannelMetaData(channel ?? null);
    const channelType = metaData?.channelType;
    const [isBlocked, setIsBlocked] = useState<boolean | null>(null);
    const [blockingOrUnblocking, setBlockingOrUnblocking] = useState(false);
    const [showNoDataMessage, setShowNoDataMessage] = useState(false);
    const sendbirdState = useSendbirdStateContext();
    const sdk = sendbirdSelectors.getSdk(sendbirdState);
    const getConnectionStatus = useGetConnectionStatus();

    const sendbirdUser = useMemo(
        () => availableUsers.find(v => v.userId === profileUserId),
        [availableUsers, profileUserId],
    );
    const profile = useMemo(() => profilesById[profileUserId], [profileUserId, profilesById]);

    const allDataAvailable = sendbirdUser && profile;
    const hasLocation = !!messagingLocation(profile);
    const hasLocalTime = !!profile?.timezone;
    const showViewInNetwork = hasLocation && profile?.prefStudentNetworkPrivacy === 'full' && !!profile?.completedAt;

    const mostRecentPositionText = useMemo(() => {
        if (!profile) return '';
        return getMostRecentPositionText(profile, t);
    }, [profile, t]);

    const programsByStartDateDesc = useMemo(() => {
        if (!profile) return [];
        if (!getPrograms(profile)) return [];

        const programs = Object.values(getPrograms(profile)!).flatMap(brand =>
            brand.programs.map(program => ({ ...program, logo: { ...brand.logo } })),
        );

        return programs.sort((a, b) => b.startDate - a.startDate);
    }, [profile]);

    const handleCloseClick = useCallback(() => {
        dispatch(setShowProfileUserId(null));
    }, [dispatch]);

    const handleSendMessageClick = useCallback(() => {
        if (onSendMessageClick) {
            onSendMessageClick();
            return;
        }

        if (sendbirdUser && currentUser?.sendbird_access_token) {
            dispatch(setShowProfileUserId(null));
            dispatch(setShowChannelInfo(false));
            dispatch(setPresetNewMessageRecipientId(sendbirdUser.userId));
            dispatch(setShowNewMessage(true));
        }
    }, [onSendMessageClick, sendbirdUser, currentUser?.sendbird_access_token, dispatch]);

    const handleViewInNetworkClick = useCallback(() => {
        loadRoute(`/student-network?ids=${profile!.userId}`);
        dispatch(setOpenChat(false));
    }, [loadRoute, profile, dispatch]);

    const handleArchiveChatClick = useCallback(async () => {
        if (!channel) return;
        await channel.hide({ hidePreviousMessages: false, allowAutoUnhide: true });
        dispatch(setShowProfileUserId(null));
        dispatch(setShowChannelInfo(false));
        dispatch(setActiveChannelUrl(''));
    }, [channel, dispatch]);

    const getIsUserBlocked = useCallback(async () => {
        if (!sdk) return;
        const blockedUsers = await sdk.createBlockedUserListQuery({ userIdsFilter: [profileUserId] }).next();
        setIsBlocked(blockedUsers.length > 0);
    }, [profileUserId, sdk]);

    const handleBlockOrUnblockUserClick = useCallback(async () => {
        if (!sdk) return;
        setBlockingOrUnblocking(true);
        if (isBlocked) {
            await sdk.unblockUserWithUserId(profileUserId);
        } else {
            await sdk.blockUserWithUserId(profileUserId);
        }
        await getIsUserBlocked();
        setBlockingOrUnblocking(false);
    }, [profileUserId, sdk, isBlocked, getIsUserBlocked]);

    useEffect(() => {
        getIsUserBlocked();
    }, [getIsUserBlocked]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowNoDataMessage(true);
        }, 300);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <div className={classes.container}>
            <div className={classes.header.container}>
                <h3 className={classes.header.text}>{t('messaging.messaging.profile')}</h3>
                <button type="button" className={classes.header.closeButton} onClick={handleCloseClick}>
                    <FontAwesomeIcon className={classes.header.closeIcon} icon={faTimes} />
                </button>
            </div>

            {allDataAvailable && (
                <div className={classes.body.container}>
                    <div className={classes.profile.container}>
                        <div className={classes.profile.avatar.container}>
                            <MemberAvatars
                                className={classes.profile.avatar.avatarImage}
                                members={[sendbirdUser]}
                                showOnlineStatus={false}
                            />
                        </div>
                        <div className={classes.profile.nameAndPosition.container}>
                            <h4 className={classes.profile.nameAndPosition.userName}>{sendbirdUser.nickname}</h4>
                            <p className={classes.profile.nameAndPosition.mostRecentPosition}>
                                {mostRecentPositionText}
                            </p>
                        </div>
                        <div className={classes.profile.currentStateData.container}>
                            {hasLocation && (
                                <p className={classes.profile.currentStateData.item}>
                                    <FontAwesomeIcon
                                        className={classes.profile.currentStateData.itemIcon}
                                        icon={faLocationDot}
                                    />
                                    <span className={classes.profile.currentStateData.itemText}>
                                        {messagingLocation(profile)}
                                    </span>
                                </p>
                            )}
                            <p className={classes.profile.currentStateData.item}>
                                <span
                                    className={classes.profile.currentStateData.itemOnlineStatus(
                                        getConnectionStatus(sendbirdUser) === 'online',
                                    )}
                                />
                                <span className={classes.profile.currentStateData.itemText}>
                                    {t(
                                        `messaging.messaging.${
                                            getConnectionStatus(sendbirdUser) === 'online' ? 'online' : 'offline'
                                        }`,
                                    )}
                                </span>
                            </p>
                            {hasLocalTime && (
                                <p className={classes.profile.currentStateData.item}>
                                    <FontAwesomeIcon
                                        className={classes.profile.currentStateData.itemIcon}
                                        icon={faClock}
                                    />
                                    <span className={classes.profile.currentStateData.itemText}>
                                        {moment(new Date()).tz(profile.timezone!)?.format('h:mm A')}{' '}
                                        {t('messaging.messaging.local_time')}
                                    </span>
                                </p>
                            )}
                        </div>
                        <div className={classes.profile.actions.container}>
                            <DetailsPanelActionButton onClick={handleSendMessageClick}>
                                {t('messaging.messaging.message')}
                            </DetailsPanelActionButton>
                            {showViewInNetwork && (
                                <DetailsPanelActionButton onClick={handleViewInNetworkClick}>
                                    {t('messaging.messaging.view_in_network')}
                                </DetailsPanelActionButton>
                            )}
                        </div>
                    </div>
                    {programsByStartDateDesc.length > 0 && (
                        <div className={classes.programs.container}>
                            <h4 className={classes.programs.heading}>{t('messaging.messaging.programs')}</h4>
                            <ul className={classes.programs.list}>
                                {programsByStartDateDesc.map(p => (
                                    <li key={`${p.title}-${p.startDate}`} className={classes.programs.listItem}>
                                        <img
                                            className={classes.programs.logo}
                                            src={p.logo.imageOnly || ''}
                                            alt={p.logo.alt}
                                        />{' '}
                                        <span>{p.title.replace(',', '')}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    <div className={classes.additionalActions.container}>
                        <h4 className={classes.additionalActions.heading}>
                            {t('messaging.messaging.additional_actions')}
                        </h4>
                        {channelType === '1-1' && (
                            <DetailsPanelActionButton onClick={handleArchiveChatClick}>
                                {t('messaging.messaging.archive_chat')}
                            </DetailsPanelActionButton>
                        )}
                        {isBlocked !== null && (
                            <DetailsPanelActionButton
                                variant="danger"
                                onClick={handleBlockOrUnblockUserClick}
                                disabled={blockingOrUnblocking}
                            >
                                {blockingOrUnblocking ? (
                                    <FrontRoyalSpinner color="force-brand" className="no-delay no-top-margin" />
                                ) : (
                                    t(`messaging.messaging.${isBlocked ? 'unblock_user' : 'block_user'}`)
                                )}
                            </DetailsPanelActionButton>
                        )}
                    </div>
                </div>
            )}
            {!allDataAvailable && showNoDataMessage && (
                <div className={classes.noData.container}>
                    <p className={classes.noData.message}>{t('messaging.messaging.profile_not_available')}</p>
                </div>
            )}
        </div>
    );
};
