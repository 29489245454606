import { useFormContext } from 'FrontRoyalReactHookForm';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import clsx from 'clsx';
import { Select } from '../Select';

const classes = {
    form: {
        mainFieldWrapper: clsx('w-full', 'flex', 'justify-start', 'items-start', 'flex-wrap'),
        mainField: clsx('grow', 'me-4', 'mb-[10px]'),
        mainFieldInput: clsx(
            'w-full',
            'min-w-[200px]',
            'p-2',
            'border',
            'border-solid',
            'rounded-4',
            'border-slate-grey-mid-light',
            'text-black',
        ),
        advancedToggle: clsx(
            'grow-0',
            'cursor-pointer',
            'flex',
            'mb-[10px]',
            'items-center',
            'justify-between',
            'self-center',
            'text-black',
        ),
        advancedToggleIcon: (showingAdvancedFields: boolean) =>
            clsx('text-black', 'text-[14px]', 'ms-[8px]', 'transition-all', {
                'rotate-180': showingAdvancedFields,
            }),
    },
};
export function MessageSearchMainFields({
    submitHandler,
    showingAdvancedFields,
    setShowingAdvancedFields,
}: {
    submitHandler: () => void;
    showingAdvancedFields: boolean;
    setShowingAdvancedFields: (showingAdvancedFields: boolean) => void;
}) {
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    const { register } = useFormContext();

    return (
        <div className={classes.form.mainFieldWrapper}>
            <div className={classes.form.mainField}>
                <input
                    {...register('keyword')}
                    placeholder={`${t('messaging.search_placeholder')}`}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            submitHandler();
                        }
                    }}
                    className={classes.form.mainFieldInput}
                />
            </div>
            <div className={classes.form.mainField}>
                <Select {...register('searchChannels')}>
                    <option value="active">{t('messaging.this_chat')}</option>
                    <option value="all">{t('messaging.all_chats')}</option>
                </Select>
            </div>
            <button
                type="button"
                className={classes.form.advancedToggle}
                onClick={() => setShowingAdvancedFields(!showingAdvancedFields)}
            >
                <span>{t('messaging.more')}</span>
                <FontAwesomeIcon
                    icon={faChevronDown}
                    className={classes.form.advancedToggleIcon(showingAdvancedFields)}
                />
            </button>
        </div>
    );
}
