/* eslint-disable max-lines-per-function */
import { type ChangeEvent, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { faPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSendbirdStateContext } from '@sendbird/uikit-react/useSendbirdStateContext';
import sendbirdSelectors from '@sendbird/uikit-react/sendbirdSelectors';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { ImageWithFallback } from 'ImageWithFallback';
import { faCommentLines } from '@fortawesome/pro-thin-svg-icons/faCommentLines';
import { type User } from '@sendbird/chat';
import { type GroupChannel } from '@sendbird/chat/groupChannel';
import { type FileMessage, type UserMessage } from '@sendbird/chat/message';
import { type CoreMessageType } from '@sendbird/uikit-react/types/utils';
import { useTranslation } from 'react-i18next';
import { storeProvider } from 'ReduxHelpers';
import { ChannelWrapperContext } from '../ChannelWrapperContext';
import { useChannelMetaData, getEmojiFromEmojiKey, useEmojiPicker, EMOJI_CONTENT_REGEX } from '../utils';
import { getReplyMessageId } from '../selectors';
import { isImageMessage, isVideoMessage } from '../utils/typeUtils';
import { setActiveChannelUrl, setReplyMessageId } from '../actions';
import { FilePicker } from './FilePicker';
import { AddEmojiIcon } from './AddEmojiIcon';

const classes = {
    container: (parentMessage: boolean) =>
        clsx('mt-[5px]', 'border-0', { 'border-t border-slate-grey-lightest': parentMessage }, 'px-[24.5px]'),
    parentMessage: {
        container: clsx('flex', 'justify-between', 'py-4'),
        image: {
            container: clsx('me-2', 'flex', 'items-center'),
            content: clsx('max-h-11', 'rounded-5'),
        },
        messageInfo: {
            container: clsx('flex', 'w-full', 'flex-col'),
            user: clsx('font-semibold', 'text-[#000000e0]', 'text-xs', 'flex'),
            content: clsx('text-[#00000061]', 'text-xxs', 'break-anywhere', 'line-clamp-3'),
        },
        exitButton: clsx(
            'me-4',
            'text-xl',
            'p-1',
            'rounded-5',
            'hover:bg-slate-grey-lightest',
            'flex',
            'items-center',
            'justify-center',
            'w-[30px]',
            'h-[30px]',
        ),
    },
    input: {
        container1: clsx(
            'flex',
            'rounded-13',
            'border',
            'border-slate-grey-mid-light',
            'bg-white',
            'px-4',
            'py-3',
            'text-slate-grey',
            'shadow-smallish',
        ),
        container2: clsx('flex', 'flex-1', 'justify-between', 'self-end'),
        commentIcon: {
            wrapper: clsx('flex', 'items-center'),
            icon: clsx('text-xl', 'text-slate-grey', 'me-[15px]'),
        },
        textArea: {
            wrapper: clsx('grid', 'w-full'),
            content: (isEmojiContent: boolean) =>
                clsx(
                    'overflow-hidden',
                    'resize-none',
                    'max-h-[285px]',
                    'overflow-y-auto',
                    'bg-transparent',
                    'placeholder-slate-grey',
                    'outline-none',
                    'scrollbar-hide',
                    'disabled:cursor-not-allowed',
                    'disabled:placeholder-slate-grey-mid-light',
                    { 'text-[16px]': !isEmojiContent },
                    { 'text-[40px]': isEmojiContent },
                ),
            contentClone: (isEmojiContent: boolean) =>
                clsx(
                    'invisible',
                    'whitespace-pre-wrap',
                    'resize-none',
                    'max-h-[285px]',
                    'overflow-y-auto',
                    'bg-transparent',
                    'placeholder-slate-grey',
                    'outline-none',
                    'scrollbar-hide',
                    'disabled:cursor-not-allowed',
                    'disabled:placeholder-slate-grey-mid-light',
                    { 'text-[16px]': !isEmojiContent },
                    { 'text-[40px]': isEmojiContent },
                ),
        },
        iconButton: {
            wrapper: clsx('flex', 'ms-auto', 'items-center', 'justify-center'),
            submitButton: clsx(
                'm-0',
                'block',
                'border-none',
                'bg-transparent',
                'p-0',
                'transition-opacity hover:brightness-90',
                'disabled:cursor-not-allowed',
                'disabled:opacity-80',
                'quantic:text-coral',
                'valar:text-blue',
            ),
            emojiButton: clsx('me-[10px]', 'disabled:cursor-not-allowed', 'disabled:opacity-80'),
            icon: clsx('text-md', 'rtl:-scale-x-100'),
        },
    },
};

const handlePendingMessage = (scroll: (animate: boolean) => void) => {
    storeProvider.dispatch(setReplyMessageId(null));
    setTimeout(() => {
        scroll(true);
    }, 250);
};

const handleSuccessMessage = (
    currentChannelSet: boolean,
    newChannelUrl: string,
    scroll: (animate: boolean) => void,
) => {
    if (!currentChannelSet) {
        storeProvider.dispatch(setActiveChannelUrl(newChannelUrl));
    }
    setTimeout(() => {
        scroll(true);
    }, 250);
};

const MessageInputComponent = ({
    currentChannel,
    scrollToBottom = () => undefined,
    parentMessage,
    usersToMessage,
    existingChannelUrl,
    onInputContentChanged,
}: {
    currentChannel?: GroupChannel;
    scrollToBottom?: (animated?: boolean) => void;
    parentMessage?: CoreMessageType | null;
    usersToMessage?: User[];
    existingChannelUrl?: string;
    onInputContentChanged?: (newContent: string) => void;
}) => {
    const messagingContext = useSendbirdStateContext();
    const dispatch = useDispatch();
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    const isNewChannel = useMemo(() => !!usersToMessage, [usersToMessage]);

    const sendUserMessage = sendbirdSelectors.getSendUserMessage(messagingContext);
    const sbSendFileMessage = sendbirdSelectors.getSendFileMessage(messagingContext);
    const createChannel = sendbirdSelectors.getCreateGroupChannel(messagingContext);
    const getChannel = sendbirdSelectors.getGetGroupChannel(messagingContext);
    const channelWrapper = useContext(ChannelWrapperContext);
    const metaData = useChannelMetaData(currentChannel ?? null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const emojiTriggerRef = useRef<HTMLButtonElement>(null);

    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
    const [text, setText] = useState('');
    const isEmpty = useMemo(() => text.trim().length < 1, [text]);

    const isEmojiContent = useMemo(() => EMOJI_CONTENT_REGEX.test(text), [text]);

    const is1on1WithDeactivatedUser = useMemo(() => {
        if (!currentChannel || !metaData) return false;
        const myUserId = messagingContext.config.userId;
        const otherUserIsDeactivated = currentChannel.members.some(
            member => member.userId !== myUserId && !member.isActive,
        );
        return metaData.channelType === '1-1' && otherUserIsDeactivated;
    }, [currentChannel, metaData, messagingContext?.config?.userId]);

    const inputChangeHandler = useCallback(
        (event: ChangeEvent<HTMLTextAreaElement>) => {
            setText(event.target.value);
            onInputContentChanged?.(event.target.value);
        },
        [onInputContentChanged],
    );

    const getOrCreateChannel = useCallback(async () => {
        let channel = existingChannelUrl ? await getChannel(existingChannelUrl) : currentChannel;

        if (usersToMessage?.length && !channel) {
            channel = await createChannel({
                invitedUserIds: usersToMessage.map(u => u.userId),
            }).then(c => {
                c.createMetaData({ channelType: usersToMessage.length > 1 ? 'group' : '1-1' });
                return c;
            });
        }

        // If the channel is not fully joined, invite all members, see https://trello.com/c/BjnRquGN
        if (channel && channel.joinedMemberCount !== channel.memberCount) {
            await channel.inviteWithUserIds(channel.members.map(u => u.userId));
        }

        return channel!;
    }, [createChannel, currentChannel, existingChannelUrl, getChannel, usersToMessage]);

    const sendFileMessage = useCallback(
        async (file: File | Blob) => {
            const channel = await getOrCreateChannel();

            sbSendFileMessage(channel!, {
                file,
                parentMessageId: parentMessage?.messageId,
                isReplyToChannel: !!parentMessage,
            })
                .onPending(_p => {
                    dispatch(setReplyMessageId(null));
                    setTimeout(() => {
                        scrollToBottom(true);
                    }, 250);
                })
                .onPending(_p => handlePendingMessage(scrollToBottom))
                .onSucceeded(_m => handleSuccessMessage(!!currentChannel, channel.url, scrollToBottom))
                .onFailed((_err, m) => {
                    if (m?.isResendable) {
                        setTimeout(() => {
                            channel
                                .resendMessage(m as FileMessage, file)
                                .onSucceeded(_m => handleSuccessMessage(!!currentChannel, channel.url, scrollToBottom));
                        }, 250);
                    }
                });
        },
        [getOrCreateChannel, sbSendFileMessage, parentMessage, dispatch, scrollToBottom, currentChannel],
    );

    const submitText = useCallback(async () => {
        if (isEmpty) return;

        const channel = await getOrCreateChannel();

        sendUserMessage(channel, {
            message: text,
            parentMessageId: parentMessage?.messageId,
            isReplyToChannel: !!parentMessage,
        })
            .onPending(_p => {
                setText('');
                handlePendingMessage(scrollToBottom);
            })
            .onSucceeded(_m => handleSuccessMessage(!!currentChannel, channel.url, scrollToBottom))
            .onFailed((_err, m) => {
                if (m?.isResendable) {
                    setTimeout(() => {
                        channel
                            .resendMessage(m as UserMessage)
                            .onSucceeded(_m => handleSuccessMessage(!!currentChannel, channel.url, scrollToBottom));
                    }, 150);
                }
            });
    }, [currentChannel, getOrCreateChannel, parentMessage, scrollToBottom, sendUserMessage, text, isEmpty]);

    const handleEmojiSelected = useCallback(
        (emojiKey: string) => {
            if (!textAreaRef.current) return;
            const currentText = text;
            const emojiCharacter = getEmojiFromEmojiKey(emojiKey);
            const cursorPos = textAreaRef.current.selectionStart;
            const newText =
                cursorPos || (cursorPos === 0 && !!currentText?.length)
                    ? currentText.slice(0, cursorPos) + emojiCharacter + currentText.slice(cursorPos)
                    : currentText + emojiCharacter;
            setText(newText);
            onInputContentChanged?.(newText);
            textAreaRef.current.focus();
            setEmojiPickerOpen(false);
            setTimeout(() => {
                textAreaRef.current?.setSelectionRange(
                    cursorPos + emojiCharacter.length,
                    cursorPos + emojiCharacter.length,
                );
            }, 10);
        },
        [textAreaRef, text, onInputContentChanged],
    );

    const { EmojiPicker, setPositionFromElement } = useEmojiPicker({
        open: emojiPickerOpen,
        onEmojiSelection: handleEmojiSelected,
        showReactions: false,
    });

    return (
        <div className={classes.container(!!parentMessage)}>
            {parentMessage && (
                <div className={classes.parentMessage.container}>
                    <div className={classes.parentMessage.image.container}>
                        {isImageMessage(parentMessage) && (
                            <ImageWithFallback
                                src={parentMessage.url}
                                alt="parent message"
                                width={100}
                                height={100}
                                className={classes.parentMessage.image.content}
                            />
                        )}
                        {isVideoMessage(parentMessage) && (
                            <video
                                src={parentMessage.url}
                                className={classes.parentMessage.image.content}
                                width={100}
                                height={100}
                            >
                                <track kind="captions" />
                            </video>
                        )}
                    </div>
                    <div className={classes.parentMessage.messageInfo.container}>
                        <p className={classes.parentMessage.messageInfo.user}>
                            {parentMessage.isAdminMessage()
                                ? t('messaging.replyToAdmin')
                                : t('messaging.replyToName', { name: parentMessage.sender.nickname })}
                        </p>
                        <p className={classes.parentMessage.messageInfo.content}>
                            {parentMessage.isFileMessage()
                                ? parentMessage.name
                                : !parentMessage.isMultipleFilesMessage()
                                ? parentMessage.message
                                : t('messaging.files')}
                        </p>
                    </div>
                    <button
                        onClick={() => dispatch(setReplyMessageId(null))}
                        type="button"
                        className={classes.parentMessage.exitButton}
                    >
                        <FontAwesomeIcon icon={faXmark} color="#708090" />
                    </button>
                </div>
            )}
            <div className={classes.input.container1}>
                <div className={classes.input.container2}>
                    <div className={classes.input.commentIcon.wrapper}>
                        <FontAwesomeIcon icon={faCommentLines} className={classes.input.commentIcon.icon} />
                    </div>
                    <div className={classes.input.textArea.wrapper}>
                        <textarea
                            ref={textAreaRef}
                            className={classes.input.textArea.content(isEmojiContent)}
                            style={{ gridArea: '1 / 1 / 2 / 2' }}
                            rows={1}
                            onChange={inputChangeHandler}
                            disabled={(usersToMessage && !usersToMessage.length) || is1on1WithDeactivatedUser}
                            value={text}
                            onKeyDown={e => {
                                if (e.key !== 'Enter') return;
                                e.preventDefault();
                                submitText();
                            }}
                            placeholder={
                                parentMessage
                                    ? t('messaging.replyTo')!
                                    : isNewChannel
                                    ? t('messaging.startNewMessage')!
                                    : currentChannel?.members.filter(
                                          ({ userId }) => userId !== messagingContext.config.userId,
                                      ).length === 1
                                    ? t('messaging.messageTo', {
                                          name: currentChannel?.members.find(
                                              member => member.userId !== messagingContext.config.userId,
                                          )!.nickname,
                                      })!
                                    : t('messaging.messageGroup')!
                            }
                        />
                        <div
                            aria-hidden
                            // See AutoGrowTextAreaField.tsx for what is happening here
                            className={clsx(classes.input.textArea.contentClone(isEmojiContent))}
                            style={{ gridArea: '1 / 1 / 2 / 2' }}
                        >
                            {text}
                        </div>
                    </div>
                    <div className={classes.input.iconButton.wrapper}>
                        <button
                            ref={emojiTriggerRef}
                            className={classes.input.iconButton.emojiButton}
                            type="button"
                            disabled={(usersToMessage && !usersToMessage.length) || is1on1WithDeactivatedUser}
                            onClick={e => {
                                setPositionFromElement(e.currentTarget, channelWrapper?.current ?? undefined);
                                setEmojiPickerOpen(!emojiPickerOpen);
                            }}
                        >
                            <AddEmojiIcon height="24px" width="24px" />
                        </button>
                        {EmojiPicker}
                    </div>
                    <div className={classes.input.iconButton.wrapper}>
                        {isEmpty ? (
                            <FilePicker
                                sendFileMessage={sendFileMessage}
                                disabled={!!usersToMessage && !usersToMessage?.length}
                            />
                        ) : (
                            <button
                                className={classes.input.iconButton.submitButton}
                                type="submit"
                                onClick={submitText}
                            >
                                <FontAwesomeIcon className={classes.input.iconButton.icon} icon={faPaperPlaneTop} />
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const MessageInputInContext = () => {
    const { currentChannel, scrollToBottom, messages } = useGroupChannelContext();
    const parentMessageId = useSelector(getReplyMessageId);

    const parentMessage = useMemo(
        () =>
            (parentMessageId
                ? messages.find(m => m.messageId === parentMessageId) || null
                : null) as CoreMessageType | null,
        [messages, parentMessageId],
    );

    return (
        <MessageInputComponent
            currentChannel={currentChannel!}
            scrollToBottom={scrollToBottom}
            parentMessage={parentMessage}
        />
    );
};

export const MessageInputWithoutContext = ({
    usersToMessage,
    existingChannel,
    onInputContentChanged,
}: {
    usersToMessage?: User[];
    existingChannel?: string;
    onInputContentChanged?: (newContent: string) => void;
}) => (
    <MessageInputComponent
        usersToMessage={usersToMessage}
        existingChannelUrl={existingChannel}
        onInputContentChanged={onInputContentChanged}
    />
);
