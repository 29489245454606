import { createRoot } from 'react-dom/client';
import { ReactWrapper } from 'FrontRoyalReact/ReactWrapper';
import { type auto } from 'angular';
import { storeProvider } from 'ReduxHelpers';
import { Modal } from './Modal';
import { type ModalProps } from './Modal.types';

/**
 * Renders a React element outside of the main React tree using a new root
 * @returns A cleanup function that unmounts the component and removes the container
 */
export function createOutsideModal(angularInjector: auto.IInjectorService, { onClose, children, ...rest }: ModalProps) {
    const container = document.createElement('div');

    document.body.appendChild(container);
    const root = createRoot(container);

    const close = () => {
        onClose?.();
        root.unmount();
        document.body.removeChild(container);
    };

    if (storeProvider.store) {
        root.render(
            <ReactWrapper store={storeProvider.store} angularInjector={angularInjector} classNames="h-full">
                <Modal onClose={close} {...rest}>
                    {children}
                </Modal>
            </ReactWrapper>,
        );
    }

    return close;
}
