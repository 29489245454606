import { useTranslation } from 'react-i18next';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { MessagingModal } from '../MessagingModal';

type Props = {
    deleteModalRef: React.RefObject<HTMLDivElement>;
    open: boolean;
    onConfirmDelete: () => void;
    onCancel: () => void;
    onClose: () => void;
};

const classes = {
    confirm: {
        wrapper: clsx(
            'bg-white',
            'p-5',
            'shadow-lg',
            'w-[500px]',
            'h-auto',
            'min-h-[200px]',
            'max-w-[95%]',
            'max-h-[95%]',
        ),
        header: {
            wrapper: clsx('flex', 'items-start', 'justify-between', 'mb-[60px]'),
            message: clsx('text-black', 'text-lg', 'font-semibold'),
            closeButton: clsx(
                'hover:bg-slate-grey-lightest',
                'flex',
                'justify-center',
                'items-center',
                'rounded-5',
                'p-2',
                'cursor-pointer',
            ),
            closeIcon: clsx('text-black', 'text-lg'),
        },
        deleteError: clsx('text-red-600', 'text-sm', 'mb-2'),
        actions: {
            wrapper: clsx('flex', 'justify-end', 'gap-2'),
            button: (danger: boolean) =>
                clsx(
                    'px-5',
                    'py-3',
                    'flex',
                    'items-center',
                    'justify-center',
                    'rounded-5',
                    'border',
                    'border-solid',
                    'border-slate-grey',
                    {
                        'bg-white hover:bg-slate-grey-lightest text-black': !danger,
                        'bg-[var(--sendbird-light-error-300)] hover:bg-[var(--sendbird-light-error-400)] text-white':
                            danger,
                    },
                ),
        },
    },
};

export function ConfirmDeleteModal({ deleteModalRef, open, onConfirmDelete, onCancel, onClose }: Props) {
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });

    return (
        <MessagingModal open={open}>
            <div ref={deleteModalRef} className={classes.confirm.wrapper}>
                <div className={classes.confirm.header.wrapper}>
                    <p className={classes.confirm.header.message}>{t('messaging.confirm_delete_this_message')}</p>
                    <button className={classes.confirm.header.closeButton} type="button" onClick={onClose}>
                        <FontAwesomeIcon className={classes.confirm.header.closeIcon} icon={faTimes} />
                    </button>
                </div>
                <div className={classes.confirm.actions.wrapper}>
                    <button className={classes.confirm.actions.button(false)} type="button" onClick={onCancel}>
                        {t('messaging.cancel')}
                    </button>
                    <button className={classes.confirm.actions.button(true)} type="button" onClick={onConfirmDelete}>
                        {t('messaging.delete_message')}
                    </button>
                </div>
            </div>
        </MessagingModal>
    );
}
