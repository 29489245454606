import { type User } from '@sendbird/chat';
import { getMostRecentPositionText } from 'CareerProfiles';
import { clsx } from 'clsx';
import { type FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useGetStudentsToMessage } from '../utils';
import { MemberAvatars } from './MemberAvatars';
import { setShowProfileUserId } from '../actions';

const classes = {
    container: clsx('flex', 'flex-col', 'h-screen', 'justify-end', 'px-[5px]', 'py-[5px]'),
    button: {
        container: clsx('mb-[22px]'),
        element: clsx(
            'h-[40px]',
            'w-[136px]',
            'border',
            'border-slate-grey-mid-light',
            'rounded-2',
            'text-[16px]',
            'font-semibold',
            'text-slate-grey',
            'pt-[1px]',
            'uppercase',
            'flex',
            'items-center',
            'justify-center',
            'transition-colors',
            'hover:bg-slate-grey-lightest',
        ),
    },
    usersText: (oneUser: boolean) => clsx(oneUser ? 'mb-5' : 'mb-[30px]', 'text-black', 'text-[16px]'),
    images: {
        container: (oneUser: boolean) => clsx(oneUser ? 'mb-[17px]' : 'mb-[21px]', 'flex', 'flex-wrap'),
        soloAvatar: clsx(
            'w-[100px]',
            'h-[100px]',
            'sm:w-[80px]',
            'sm:h-[80px]',
            'md:w-[100px]',
            'md:h-[100px]',
            'me-[12px]',
            'shrink-0',
        ),
        multiAvatarItem: clsx('me-2', 'h-[60px]', 'w-[60px]'),
        text: clsx('flex', 'flex-col', 'justify-center', 'text-black', 'leading-5', 'flex', 'break-anywhere'),
        name: clsx('text-black', 'text-lg', 'font-semibold', 'flex', 'break-anywhere'),
    },
};

export const NewChannelInfo: FC<{
    users: User[];
    // eslint-disable-next-line max-lines-per-function
}> = ({ users }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    const { t: tWithoutPrefix } = useTranslation('back_royal');
    const oneUserSelected = useMemo(() => users.length === 1, [users.length]);
    const { profilesById } = useGetStudentsToMessage();

    return users.length ? (
        <div className={classes.container}>
            <div className={classes.images.container(oneUserSelected)}>
                {oneUserSelected ? (
                    <>
                        <MemberAvatars
                            members={[users[0]]}
                            showOnlineStatus={false}
                            className={classes.images.soloAvatar}
                        />
                        <div className={classes.images.text}>
                            <p className={classes.images.name}>{users[0].nickname}</p>
                            {profilesById[users[0].userId] && (
                                <p>{getMostRecentPositionText(profilesById[users[0].userId], tWithoutPrefix)}</p>
                            )}
                        </div>
                    </>
                ) : (
                    users.map(u => (
                        <button
                            key={u.userId}
                            type="button"
                            onClick={() => {
                                dispatch(setShowProfileUserId(u.userId));
                            }}
                        >
                            <MemberAvatars
                                members={[u]}
                                showOnlineStatus={false}
                                className={classes.images.multiAvatarItem}
                            />
                        </button>
                    ))
                )}
            </div>
            <div className={classes.usersText(oneUserSelected)}>
                {oneUserSelected ? (
                    <p>
                        {t('messaging.thisConversation1')}
                        <span className="font-semibold">{users[0].nickname}</span>
                        {t('messaging.thisConversation2')}
                    </p>
                ) : (
                    <p>
                        {t('messaging.beginningOfHistory')}
                        {users.map((u, _, a) => (
                            <Fragment key={u.userId}>
                                <span className="font-semibold">{u.nickname}</span>
                                {u === a.at(-1)
                                    ? t('messaging.period')
                                    : u === a.at(-2)
                                    ? t('messaging.and')
                                    : t('messaging.comma')}
                            </Fragment>
                        ))}
                    </p>
                )}
            </div>
            {oneUserSelected && (
                <div className={classes.button.container}>
                    <button
                        className={classes.button.element}
                        type="button"
                        onClick={() => {
                            dispatch(setShowProfileUserId(users[0].userId));
                        }}
                    >
                        {t('messaging.viewProfile')}
                    </button>
                </div>
            )}
        </div>
    ) : null;
};
