import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { type ContextItemPosition } from '../../messaging.types';

type Props = {
    menuRef: React.RefObject<HTMLDivElement>;
    onReplyClick: () => void;
    onCopyClick: () => void;
    onDeleteClick: () => void;
    hasReply: boolean;
    hasParent: boolean;
    messageIsFromOtherUser: boolean;
    activeChannelUrl: string;
    position: ContextItemPosition;
    open: boolean;
};

const classes = {
    menu: {
        wrapper: (open: boolean) =>
            clsx(
                { hidden: !open },
                { block: open },
                'z-[100000000]',
                'bg-white',
                'shadow-lg',
                'absolute',
                'h-auto',
                'w-auto',
                'min-w-[150px]',
                'p-0',
            ),
        list: clsx('list-none', 'm-0', 'p-0'),
        listItem: clsx('m-0', 'p-0'),
        listItemButton: clsx(
            'w-full',
            'border-0',
            'bg-transparent',
            'py-3',
            'sm:py-1',
            'px-3',
            'text-left',
            'text-black',
            'hover:bg-slate-grey-lightest',
            'disabled:text-slate-grey',
            'disabled:hover:bg-transparent',
        ),
    },
};

export function ContextMenu({
    menuRef,
    onReplyClick,
    onCopyClick,
    onDeleteClick,
    hasReply,
    hasParent,
    messageIsFromOtherUser,
    activeChannelUrl,
    position,
    open,
}: Props) {
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    return (
        <div
            className={classes.menu.wrapper(open)}
            ref={menuRef}
            style={{
                top: 'top' in position ? `${position.top}px` : undefined,
                right: 'right' in position ? `${position.right}px` : undefined,
                bottom: 'bottom' in position ? `${position.bottom}px` : undefined,
                left: 'left' in position ? `${position.left}px` : undefined,
            }}
        >
            <ul className={classes.menu.list}>
                <li className={classes.menu.listItem}>
                    <button
                        className={classes.menu.listItemButton}
                        type="button"
                        onClick={onReplyClick}
                        disabled={hasParent}
                    >
                        {t('messaging.reply')}
                    </button>
                </li>
                <li className={classes.menu.listItem}>
                    <button type="button" className={classes.menu.listItemButton} onClick={onCopyClick}>
                        {t('messaging.copy')}
                    </button>
                </li>
                {!messageIsFromOtherUser && activeChannelUrl && (
                    <li className={classes.menu.listItem}>
                        <button
                            type="button"
                            className={classes.menu.listItemButton}
                            disabled={hasReply}
                            onClick={onDeleteClick}
                        >
                            {t('messaging.delete')}
                        </button>
                    </li>
                )}
            </ul>
        </div>
    );
}
