/* eslint-disable max-lines-per-function */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type GroupChannel as GroupChannelType } from '@sendbird/chat/groupChannel';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { messagingLocation, useGetStudentsToMessage, useMessagingConfig, useGetChannelMemberNickname } from '../utils';
import { setActiveChannelUrl, setShowChannelInfo, setShowProfileUserId } from '../actions';
import { MemberAvatars } from './MemberAvatars';
import { DetailsPanelActionButton } from './DetailsPanelActionButton';

const classes = {
    container: clsx('h-full', 'flex', 'flex-col'),
    header: {
        container: clsx(
            'h-[64px]',
            'grow-0',
            'shrink-0',
            'basis-[64px]',
            'border-b',
            'border-solid',
            'border-slate-grey-mid-light',
            'flex',
            'items-center',
            'justify-between',
            'ps-5',
            'pe-3',
        ),
        text: clsx('font-semibold', 'text-lg', 'text-black'),
        closeButton: clsx(
            'hover:bg-slate-grey-lightest',
            'flex',
            'justify-center',
            'items-center',
            'rounded-5',
            'p-2',
            'cursor-pointer',
        ),
        closeIcon: clsx('text-black', 'text-lg', 'overflow-hidden'),
    },
    body: {
        container: clsx('h-[calc(100%-64px)]', 'overflow-y-auto'),
    },
    avatarAndName: {
        container: clsx('p-5', 'border-b', 'border-solid', 'border-slate-grey-mid-light'),
        avatar: clsx('h-[100px]', 'w-[100px]', 'mx-auto', 'mb-[14px]'),
        names: clsx('text-xl', 'font-semibold', 'text-center', 'mb-[10px]', 'leading-[28px]', 'text-black'),
    },
    members: {
        container: clsx('pt-[30px]', 'px-0', 'pb-5', 'border-b', 'border-solid', 'border-slate-grey-mid-light'),
        heading: clsx('font-semibold', 'text-[16px]', 'mb-3', 'px-5', 'text-black'),
        list: clsx('p-0', 'm-0', 'list-none', 'w-full'),
        listItem: clsx('m-0', 'w-full'),
        listItemButton: clsx(
            'w-full',
            'flex',
            'justify-start',
            'items-start',
            'px-5',
            'py-[8px]',
            'transition-colors',
            'hover:bg-slate-grey-extra-light',
            'hover:cursor-pointer',
            'disabled:opacity-50',
            'disabled:cursor-not-allowed',
        ),
        memberAvatar: clsx('h-[30px]', 'w-[30px]', 'me-[10px]', 'mt-0', 'shrink-0'),
        avatarIndicatorOuter: clsx('h-[12px]', 'w-[12px]'),
        avatarIndicatorInner: clsx('min-w-[9px]', 'min-h-[9px]'),
        memberNameAndLocation: {
            container: clsx('grow'),
            name: clsx('block', 'text-[16px]', 'leading-[1]', 'text-start', 'font-semibold', 'text-black'),
            location: clsx('block', 'text-[13px]', 'text-start', 'text-slate-grey'),
        },
    },
    additionalActions: {
        container: clsx('pt-[30px]', 'px-5', 'pb-5'),
        heading: clsx('font-semibold', 'text-[16px]', 'mb-5', 'text-black'),
    },
};

export const GroupChannelInfo = ({ channel }: { channel: GroupChannelType }) => {
    const dispatch = useDispatch();
    const { userId } = useMessagingConfig();
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    const getChannelMemberNickname = useGetChannelMemberNickname();

    const membersThatAreNotMe = useMemo(
        () => channel.members.filter(member => member.userId !== userId),
        [channel.members, userId],
    );

    const names = useMemo(
        () =>
            membersThatAreNotMe
                .map(member => getChannelMemberNickname(member, { short: true }))
                .map((name, _, a) => {
                    const suffix =
                        name === a.at(-1)
                            ? ''
                            : name === a.at(-2)
                            ? `${membersThatAreNotMe.length > 2 ? ',' : ''} ${t('messaging.and')}`
                            : ',';
                    return `${name}${suffix}`;
                })
                .join(' '),
        [membersThatAreNotMe, getChannelMemberNickname, t],
    );

    const truncatedNames = useMemo(() => {
        if (membersThatAreNotMe.length <= 4) return names;

        const membersOverTwo = membersThatAreNotMe.length - 2;
        const firstNames = membersThatAreNotMe
            .slice(0, 2)
            .map(member => getChannelMemberNickname(member, { short: true }));
        return `${firstNames[0]}, ${firstNames[1]}, ${t('messaging.and_n_others', { count: membersOverTwo })}`;
    }, [membersThatAreNotMe, names, getChannelMemberNickname, t]);

    const { profilesById } = useGetStudentsToMessage();

    const handleCloseClick = useCallback(() => {
        dispatch(setShowChannelInfo(false));
    }, [dispatch]);

    const handleMemberClick = useCallback(
        (memberUserId: string) => {
            dispatch(setShowProfileUserId(memberUserId));
        },
        [dispatch],
    );

    const handleArchiveChatClick = useCallback(async () => {
        if (!channel) return;
        await channel.hide({ hidePreviousMessages: false, allowAutoUnhide: true });
        dispatch(setShowProfileUserId(null));
        dispatch(setShowChannelInfo(false));
        dispatch(setActiveChannelUrl(''));
    }, [channel, dispatch]);

    const handleLeaveChatClick = useCallback(async () => {
        if (!channel) return;
        await channel.leave();
        dispatch(setShowProfileUserId(null));
        dispatch(setShowChannelInfo(false));
        dispatch(setActiveChannelUrl(''));
    }, [channel, dispatch]);

    return (
        <div className={classes.container}>
            <div className={classes.header.container}>
                <h3 className={classes.header.text}>{t('messaging.chat_information')}</h3>
                <button type="button" className={classes.header.closeButton} onClick={handleCloseClick}>
                    <FontAwesomeIcon className={classes.header.closeIcon} icon={faTimes} />
                </button>
            </div>
            <div className={classes.body.container}>
                <div className={classes.avatarAndName.container}>
                    <MemberAvatars
                        members={membersThatAreNotMe}
                        showOnlineStatus={false}
                        className={classes.avatarAndName.avatar}
                    />
                    <h3 className={classes.avatarAndName.names}>{truncatedNames}</h3>
                </div>

                <div className={classes.members.container}>
                    <h4 className={classes.members.heading}>{t('messaging.members')}</h4>
                    <ul className={classes.members.list}>
                        {membersThatAreNotMe.map(member => {
                            const profile = profilesById[member.userId];
                            const memberName = getChannelMemberNickname(member);
                            return (
                                <li key={member.userId} className={classes.members.listItem}>
                                    <button
                                        type="button"
                                        className={classes.members.listItemButton}
                                        onClick={() => handleMemberClick(member.userId)}
                                        disabled={!member.isActive}
                                    >
                                        <MemberAvatars
                                            members={[member]}
                                            className={classes.members.memberAvatar}
                                            showOnlineStatus
                                            onlineIndicatorOuterClassName={classes.members.avatarIndicatorOuter}
                                            onlineIndicatorInnerClassName={classes.members.avatarIndicatorInner}
                                        />
                                        <span className={classes.members.memberNameAndLocation.container}>
                                            <span className={classes.members.memberNameAndLocation.name}>
                                                {memberName}
                                            </span>
                                            {profile && (
                                                <span className={classes.members.memberNameAndLocation.location}>
                                                    {messagingLocation(profile)}
                                                </span>
                                            )}
                                        </span>
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className={classes.additionalActions.container}>
                    <h4 className={classes.additionalActions.heading}>{t('messaging.additional_actions')}</h4>
                    <DetailsPanelActionButton onClick={handleArchiveChatClick}>
                        {t('messaging.archive_chat')}
                    </DetailsPanelActionButton>
                    <DetailsPanelActionButton variant="danger" onClick={handleLeaveChatClick}>
                        {t('messaging.leave_chat')}
                    </DetailsPanelActionButton>
                </div>
            </div>
        </div>
    );
};
