import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useState } from 'react';
import { useFormContext } from 'FrontRoyalReactHookForm';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { MessageSearchMainFields } from './MessageSearchMainFields';
import { MessageSearchAdvancedFields } from './MessageSearchAdvancedFields';

const classes = {
    form: {
        wrapper: clsx(
            'w-full',
            'min-h-[50px]',
            'grow-0',
            'shrink-0',

            'border-b',
            'border-solid',
            'border-slate-grey-mid-light',
            'px-[22px]',
            'py-[10px]',
            'bg-white',
        ),
        formActionsWrapper: clsx('flex', 'justify-start', 'items-center', 'ms-auto'),
        submitButton: clsx(
            'h-[40px]',
            'rounded-5',
            'bg-slate-grey-extra-light',
            'text-black',
            'font-semibold',
            'text-[16px]',
            'px-[20px]',
            'flex',
            'justify-center',
            'items-center',
            'hover:bg-slate-grey-mid-light',
            'cursor-pointer',
            'disabled:opacity-40',
            'disabled:cursor-not-allowed',
            'me-[10px]',
        ),
        resetButton: clsx(
            'h-[40px]',
            'rounded-5',
            'bg-slate-grey-extra-light',
            'text-black',
            'font-semibold',
            'text-[16px]',
            'px-[20px]',
            'flex',
            'justify-center',
            'items-center',
            'cursor-pointer',
            'hover:bg-slate-grey-mid-light',
            'disabled:opacity-40',
            'disabled:cursor-not-allowed',
        ),
    },
};

export function MessageSearchForm({
    resetDisabled,
    valid,
    isLoading,
    submitHandler,
    handleResetClick,
}: {
    resetDisabled: boolean;
    valid: boolean;
    isLoading: boolean;
    submitHandler: () => void;
    handleResetClick: () => void;
}) {
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    const [showingAdvancedFields, setShowingAdvancedFields] = useState(false);
    const { handleSubmit } = useFormContext();

    return (
        <div className={classes.form.wrapper}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <form onSubmit={handleSubmit(submitHandler)}>
                    <MessageSearchMainFields
                        submitHandler={submitHandler}
                        showingAdvancedFields={showingAdvancedFields}
                        setShowingAdvancedFields={setShowingAdvancedFields}
                    />
                    <MessageSearchAdvancedFields showingAdvancedFields={showingAdvancedFields} />

                    <div className={classes.form.formActionsWrapper}>
                        <button
                            className={classes.form.submitButton}
                            type="submit"
                            disabled={isLoading || !valid}
                            onClick={submitHandler}
                        >
                            {t('messaging.search')}
                        </button>
                        <button
                            className={classes.form.resetButton}
                            type="submit"
                            onClick={handleResetClick}
                            disabled={resetDisabled}
                        >
                            {t('messaging.reset')}
                        </button>
                    </div>
                </form>
            </LocalizationProvider>
        </div>
    );
}
